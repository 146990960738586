@import "../../../Global.scss";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  min-width: 250px;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  margin-top: 12px;

  .recentOrderCards {
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 2px;
    width: 190px;
    // height: 70px;
    border-radius: 5px;
    background-color: $main-white;
    padding: 10px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);

    .icons {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      position: absolute;
      top: 5px;
      right: 5px;
      transition: 0.5s;
    }

    .openOrderIcon {
      scale: 1.1;
      &:hover {
        cursor: pointer;
      }
    }

    .closeIcon {
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.line {
  width: 80%;
  max-width: 300px;
  height: 1px;
  background-color: $gray-color;
  margin: 22px 0;
  box-shadow: 0 2px 5px rgb(0, 0, 0);
  border-radius: 10px;
}
