@import "../../../Global.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: $main-white;

  .serviceClose {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 336px;

    .infoSchedule {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      p {
        margin-top: -5px;
      }
    }

    .imageContent {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      position: relative;

      .randomImage {
        height: 200px;
        border-radius: 15px;
        margin-top: 22px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
      }

      label {
        position: absolute;
        text-shadow: 2px 2px 2px rgb(0, 0, 0);
        opacity: 0.3;
      }
    }
  }
}
