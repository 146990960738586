@import "../../../Global.scss";

.container {
  display: flex;
  flex-direction: column;
  position: fixed;
  transition: opacity 0.3s ease-in-out;
  bottom: 8px;
  right: 8px;
  gap: 4px;

  & > div {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 35px;
      z-index: 2;
      border-radius: 50px;
      background-color: $background-color;

      .quantityNotification {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        position: absolute;
        width: 16px;
        height: 16px;
        background-color: $primary-color;
        top: -6px;
        right: -4px;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;

        &.isVisible {
          opacity: 1;
        }
      }
    }
  }

  .boxContent > label {
    padding: 4px 6px;
    border-radius: 5px;
    background-color: $background-color;
    flex: 1;
  }

  &.isInvisible {
    opacity: 0;
    pointer-events: none;
  }
}
