@import "../../../Global.scss";

.container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: fit-content;
  height: fit-content;
}

.select {
  width: 288px;
  height: 48px;
  background: $main-white;
  border-radius: 5px;
  padding: 0 12px;
  font-size: 14px;
  font-weight: 600;
  color: $placeholder-color;
  border: 2px solid transparent;
  outline: none;

  &:focus {
    border: 2px solid $primary-color;
  }

  &__isNegative {
    width: 288px;
    height: 48px;
    background: $main-white;
    border-radius: 5px;
    padding: 0 12px;
    font-size: 14px;
    font-weight: 600;
    color: $placeholder-color;
    border: 2px solid $invalid-color;

    &:focus {
      border: 2px solid $invalid-color;
    }
  }
}

.labelError {
  color: $invalid-color;
  font-size: 12px;
  font-weight: 600;
  margin-top: 2px;
  margin-left: 2px;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
}

.caption {
  margin-top: 2px;
  margin-left: 2px;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
}
