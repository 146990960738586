@import "../../../Global.scss";

.container {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgb(33, 37, 41, 60%);
  backdrop-filter: blur(5px);
}

.modalContainer {
  display: flex;
  max-width: 300px;
  width: 70%;
  min-height: 250px;
  height: fit-content;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 32px 32px 40px 32px;
  background: $main-white;
  margin-bottom: 100px;
  border-radius: 8px;
  box-shadow: 0px 2px 5px 0px rgba(255, 255, 255, 0.2);
  color: $placeholder-color;
  animation: modalAnimation 0.5s ease forwards;
}

.closeModalIcon {
  position: absolute;
  top: 12px;
  right: 12px;

  &:hover {
    cursor: pointer;
  }
}

.modalImg {
  width: 100%;
  height: 190px;
  border-radius: 8px;
  box-shadow: 0 2px 4px $placeholder-color;
}

@keyframes modalAnimation {
  from {
    transform: translateY(-10px);
  }
  to {
    transform: translateY(0);
  }
}
