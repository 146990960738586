.captionContainer {
  display: flex;
  align-items: center;
  margin-top: 8px;
  gap: 4px;

  &:hover {
    cursor: pointer;
  }
}
