@import "../../Global.scss";

.infoSchedule {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 350px;

  p {
    max-width: 320px;
    margin-top: -5px;
  }
}
