@import "../../../Global.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: $main-white;
  max-width: 400px;
  margin-bottom: 40px;
  position: relative;

  .closeModal {
    position: absolute;
    top: 32px;
    left: 32px;
  }

  .menuOrder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 8px 4px;

    .quantityNotification {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50px;
      position: absolute;
      width: 18px;
      height: 18px;
      background-color: $primary-color;
      top: -6px;
      right: -2px;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;

      &.isVisible {
        opacity: 1;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  .menuTypes {
    display: flex;
    align-items: center;
    overflow-x: scroll;
    gap: 22px;
    width: 100%;
    margin-top: 32px;
    text-align: center;

    &::-webkit-scrollbar {
      height: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $background-secondary-color;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-track {
      background-color: $background-color;
    }

    .type {
      color: $main-white;

      &:first-child {
        padding-left: 16px;
      }

      &:last-child {
        padding-right: 16px;
      }

      label {
        &:hover {
          cursor: pointer;
        }
      }
    }

    .currentType {
      transform: scale(1.05) translateY(-2px);
      transition: transform 0.3s ease;

      label {
        color: $primary-color;
        font-weight: bold;
      }
    }
  }

  .menuItems {
    width: 100%;

    .menuItemsByType {
      margin-top: 32px;

      label {
        margin-left: 12px;
      }

      .item {
        margin-top: 16px;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .menuItemByTypeEmpty {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
    }
  }
}
