@import "../../../Global.scss";

.isLink {
  font-size: $font-size-small;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;

  &:hover {
    cursor: pointer;
  }
}
