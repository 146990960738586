@import "../../../Global.scss";

.alert {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
  position: fixed;
  padding: 5px 15px;
  top: 10px;
  border-radius: 15px;
  text-align: center;
  animation: alertAnimation 0.5s ease forwards;
  z-index: 99;
  color: $main-white;
  height: 60px;
  min-width: 250px;

  .closeIco {
    &:hover {
      cursor: pointer;
    }
  }
}

.success {
  background-color: $success-color;
}

.danger {
  background-color: $invalid-color;
}

@keyframes alertAnimation {
  from {
    transform: translateY(-10px);
  }
  to {
    transform: translateY(0);
  }
}
