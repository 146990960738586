@import "../../../Global.scss";

.container {
  display: flex;
  width: calc(100% - 16px);
  height: 70px;
  background-color: $main-white;
  padding: 8px;
  box-shadow: 0 0 10px black;
  // overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .itemImage {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 70px;
    min-width: 70px;
    border-radius: 5px;
    border: 1px solid $placeholder-color;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);

    & > div {
      height: 100%;
      width: 100%;
      border-radius: 5px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: (50%, 50%);
      filter: saturate(120%) contrast(105%);
    }
  }

  .itemInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;

    span {
      display: flex;
      align-items: flex-end;
      flex: 1;
    }
  }

  label {
    color: rgb(0, 0, 0);
    text-align: left;
  }

  @media screen and (min-width: 440px) {
    border-radius: 5px;
  }

  @media screen and (max-width: 350px) {
    height: auto;

    .itemImage {
      height: auto;
    }
  }
}
