@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400&family=Noto+Serif:wght@400;500;600;700&display=swap");

$primary-color: #0088c2;
$main-white: #f9f9f9;
$invalid-color: #ff5555;
$success-color: #268f3ff5;
$gray-color: #d9d9d9;
$placeholder-color: #4a4a4a;
$background-color: #212529;
$background-secondary-color: #1d2022;

$font-size-extra-small: 10px;
$font-size-small: 12px;
$font-size-medium-small: 14px;
$font-size-medium: 16px;
$font-size-medium-large: 18px;
$font-size-large: 20px;
$font-size-extra-large: 22px;

$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

body {
  background-color: $background-color;
}

body.modal-open {
  overflow: hidden;
}

* {
  font-family: "Montserrat", sans-serif;
  -webkit-tap-highlight-color: transparent;
}

.fontSizeExtraSmall {
  font-size: $font-size-extra-small;
}

.fontSizeSmall {
  font-size: $font-size-small;
}

.fontSizeMediumSmall {
  font-size: $font-size-medium-small;
}

.fontSizeMedium {
  font-size: $font-size-medium;
}

.fontSizeMediumLarge {
  font-size: $font-size-medium-large;
}

.fontSizeLarge {
  font-size: $font-size-large;
}

.fontSizeExtraLarge {
  font-size: $font-size-extra-large;
}

.fontWeightRegular {
  font-weight: $font-weight-regular;
}

.fontWeightMedium {
  font-weight: $font-weight-medium;
}

.fontWeightSemibold {
  font-weight: $font-weight-semibold;
}

.fontWeightBold {
  font-weight: $font-weight-bold;
}

.fontColorPrimaryColor {
  color: $primary-color;
}

.fontColorMainWhite {
  color: $main-white;
}

.fontColorInvalidColor {
  color: $invalid-color;
}

.fontColorSuccessColor {
  color: $success-color;
}

.fontColorGrayColor {
  color: $gray-color;
}

.fontColorPlaceholderColor {
  color: $placeholder-color;
}

.fontColorBackgroundColor {
  color: $background-color;
}

.fontColorBackgroundSecondaryColor {
  color: $background-secondary-color;
}

.textAlignJustify {
  text-align: justify;
}

.textAlignCenter {
  text-align: center;
}

.textAlignLeft {
  text-align: left;
}

.textAlignRight {
  text-align: right;
}

.backgroundPrimaryColor {
  background-color: $primary-color;
}

.backgroundMainWhite {
  background-color: $main-white;
}

.backgroundInvalidColor {
  background-color: $invalid-color;
}

.backgroundSuccessColor {
  background-color: $success-color;
}

.backgroundGrayColor {
  background-color: $gray-color;
}

.backgroundPlaceholderColor {
  background-color: $placeholder-color;
}

.backgroundBackgroundColor {
  background-color: $background-color;
}

.backgroundBackgroundSecondaryColor {
  background-color: $background-secondary-color;
}

.borderPrimaryColor {
  border: 1px solid $primary-color;
}

.borderMainWhite {
  border: 1px solid $main-white;
}

.borderInvalidColor {
  border: 1px solid $invalid-color;
}

.borderSuccessColor {
  border: 1px solid $success-color;
}

.borderGrayColor {
  border: 1px solid $gray-color;
}

.borderPlaceholderColor {
  border: 1px solid $placeholder-color;
}

.borderBackgroundColor {
  border: 1px solid $background-color;
}

.borderBackgroundSecondaryColor {
  border: 1px solid $background-secondary-color;
}
