@import "../../../Global.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 3;
  background-color: $background-color;
  overflow-y: scroll;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 350px;
    width: 85%;
    position: relative;

    .imageContent {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      position: relative;

      .randomImage {
        height: 200px;
        border-radius: 15px;
        margin-top: 22px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
      }

      label {
        position: absolute;
        text-shadow: 2px 2px 2px rgb(0, 0, 0);
        opacity: 0.3;
      }
    }

    .infoAlertMessage {
      display: flex;
      max-width: 220px;
    }
  }
}
