@import "../../../Global.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: $main-white;

  .label {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    width: 90%;
    max-width: 300px;
  }

  .inputs {
    display: flex;
    flex-direction: column;
  }

  .linkContainer {
    display: flex;
    align-items: center;
    gap: 5px;
    margin: -5px 0 0 0;

    &:hover {
      cursor: pointer;
    }
  }
}
